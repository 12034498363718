









import Vue from 'vue';
import HostProfile from '@/components/profile/HostProfile.vue';
import ChangePassword from '@/components/profile/ChangePassword.vue';
import ChangeEmail from '@/components/profile/ChangeEmail.vue';
import ChangeEmailSettings from '@/components/profile/ChangeEmailSettings.vue';
import { MetaInfo } from 'vue-meta';

export default Vue.extend({
  components: { HostProfile, ChangePassword, ChangeEmail, ChangeEmailSettings },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.host.HostAccountEdit.meta.title').toString(),
    };
  },
  mounted() {
    this.scrollToSection(this.$router.currentRoute);
    this.setBreadCrumb();
  },
  watch: {
    $route(to) {
      this.scrollToSection(to);
    },
  },
  methods: {
    scrollToSection(to: any) {
      var section = to.hash.replace('#', '');
      if (section) {
        this.$nextTick(() => {
          const element = document.getElementById(section);
          if (element) {
            const yOffset = document.getElementsByTagName('header')[0].offsetHeight;
            const y = element.getBoundingClientRect().top + window.pageYOffset - yOffset - 15;
            window.scrollTo({ top: y, behavior: 'smooth' });
          }
        });
      }
    },

    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.host.HostAccountEdit.breadcrumb.last' },
      ]);
    },
  },
});
