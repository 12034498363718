import ApiService from '@/services/ApiService';

const API_URL = '/api/owner/HostProfile/';

class HostProfileService {
  save(hostProfile: any) {
    return ApiService.post(`${API_URL}Edit`, hostProfile);
  }

  getForEdit() {
    return ApiService.get(`${API_URL}Edit`);
  }
}

export default new HostProfileService();
