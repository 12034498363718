


























































































import Vue, { VueConstructor } from 'vue';
import HelpCardMixin from '@/mixins/HelpCardMixin.vue';
import AddressFormValidations from '@/validations/AddressFormValidations';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import HostProfileService from '@/services/owner/HostProfileService';
import Notification from '@/components/shared/Notification.vue';
import AddressForm from '@/components/forms/AddressForm.vue';
import ValidatedFormCard from '@/components/shared/ValidatedFormCard.vue';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import ValidatedSelect from '@/components/forms/ValidatedSelect.vue';

class HostProfileEditViewModel {
  firstName!: string;
  companyName!: string;
  city!: string;
  country!: string;
  state!: string;
  street!: string;
  zipCode!: string;
  lastName!: string;
  displayName!: string;
  phoneNumber!: string;
  houseNumber!: string;
  companyLegalName!: string;
  companyLegalType!: string;
  companyLegalTypes!: string[];
}
const validations = {
  hostProfile: {
    ...AddressFormValidations,
    firstName: { required },
    lastName: { required },
    displayName: { required },
    phoneNumber: {},
    companyName: {},
    companyLegalType: {},
    companyLegalName: {},
  },
};

export default (Vue as VueConstructor<Vue & InstanceType<typeof HelpCardMixin>>).extend({
  components: { Notification, AddressForm, ValidatedFormCard, ValidatedInput, ValidatedSelect },
  mixins: [HelpCardMixin, validationMixin],
  validations() {
    var vals = { ...validations };
    if (!!this.hostProfile.companyName) {
      vals.hostProfile.companyLegalType = { required };
    } else {
      vals.hostProfile.companyLegalType = {};
    }
    if (!!this.hostProfile.companyLegalType) {
      vals.hostProfile.companyName = { required };
    } else {
      vals.hostProfile.companyName = {};
    }
    if (this.hostProfile.companyLegalType == 'Other') {
      vals.hostProfile.companyLegalName = { required };
    } else {
      vals.hostProfile.companyLegalName = {};
    }
    return vals;
  },
  data() {
    return {
      focus: '',
      hostProfile: new HostProfileEditViewModel(),
      originalHostProfile: new HostProfileEditViewModel(),
      ready: false,
      showSuccessNotification: false,
    };
  },
  watch: {
    companyLegalType: {
      immediate: true,
      handler() {
        if (this.hostProfile.companyLegalType == 'Other') {
          this.$v.hostProfile.companyLegalName?.$touch();
        }
        if (this.hostProfile.companyLegalType) {
          this.$v.playroom.companyName?.$touch();
        }
      },
    },
    companyName: {
      immediate: true,
      handler() {
        if (this.hostProfile.companyName) {
          this.$v.hostProfile.companyLegalType?.$touch();
        } else {
          this.$v.playroom.companyLegalType?.$reset();
          this.$v.playroom.companyName?.$reset();
        }
      },
    },
  },
  methods: {
    onFocusChange(newFocus: string) {
      if (newFocus == '') {
        this.focusFor = '';
      } else {
        this.focusFor = 'components.profile.HostProfile.info.box1';
      }
    },

    async save() {
      var response = await HostProfileService.save(this.hostProfile);
      if (response.success) {
        this.showSuccessNotification = true;
        this.originalHostProfile = { ...this.hostProfile };
        this.$store.state.AuthStoreModule.user.displayName = this.hostProfile.displayName;
      }
      return response;
    },

    onDiscardChanges() {
      this.hostProfile = { ...this.originalHostProfile };
    },

    loadData() {
      HostProfileService.getForEdit().then((res) => {
        this.hostProfile = res.value as HostProfileEditViewModel;
        this.hostProfile.companyLegalTypes.shift();
        if (this.hostProfile.companyLegalType == 'None') {
          this.hostProfile.companyLegalType = '';
        }
        this.originalHostProfile = { ...this.hostProfile };
        this.ready = true;
      });
    },
  },
  computed: {
    companyLegalTypeOptions(): { value: string; label: string }[] {
      if (this.ready && this.hostProfile.hasOwnProperty('companyLegalTypes')) {
        let cmpLegal = [];
        cmpLegal = this.hostProfile.companyLegalTypes.map((t) => ({
          value: t as string,
          label: this.$t('enums.companyLegalTypes.' + t).toString(),
        }));
        cmpLegal.splice(0, 0, { value: '', label: '' });
        return cmpLegal;
      } else {
        return [];
      }
    },
    companyLegalType(): string {
      return this.hostProfile.companyLegalType;
    },
    companyName(): string {
      return this.hostProfile.companyName;
    },
    isCompanyLegalNameDisabled(): boolean {
      return this.hostProfile.companyLegalType == 'Other';
    },
  },
  mounted() {
    this.loadData();
  },
});
